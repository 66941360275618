import React, { useState, useEffect } from "react"
import * as classes from "./demo.module.scss"



const RTS = [
  "",
  "401",
  "402",
  "404",
  "405",
  "407",
  "409",
  "410",
  "411",
  "412",
  "414",
]
const Demo = () => {
  const [route, setRoute] = useState(null)
  const [direction, setDirection] = useState(null)
  const [shouldRequest, setShouldRequest] = useState(false)
  const [busData, setBusData] = useState("🚌")

  async function getData(route = "", direction = "") {
    const url = `https://galway-bus.apis.ie/api/groute/${route}/${direction}`
  
    try {
      setBusData("🚌loading...")
      const res = await fetch(url)
      const json = res.json()
      return json
    } catch (e) {
      console.log("error getting data", e)
      return e
    }
  }

  useEffect(() => {
    if (!shouldRequest) {
      return
    }

    async function go() {
      const busData = await getData(route, direction)
      setBusData(busData)
      setShouldRequest(false)
    }
    go()
  }, [shouldRequest, route, direction])

  return (
    <section className={classes.demoWrap}>
      <h2>Demo</h2>
      <div className={`${classes.uri} preSized`}>
        <span>https://galway-bus.apis.ie/api/groute/</span>
        <span>
          <label htmlFor="route" className="sr-only">
            select route
          </label>
          <select
            name="route"
            id="route"
            onChange={e => setRoute(e.target.value)}
          >
            {RTS.map(r => (
              <option key={r} value={r} label={r ? r : "empty"}>
                {r}
              </option>
            ))}
          </select>
        </span>
        /
        <span>
          <label htmlFor="direction" className="sr-only">
            Select direction
          </label>
          <select
            name="direction"
            id="direction"
            onChange={e => setDirection(e.target.value)}
          >
            <option label="empty" value=""></option>
            <option label="0" value="0">
              0
            </option>
            <option label="1" value="1">
              1
            </option>
          </select>
        </span>
        <button onClick={() => setShouldRequest(true)}>Send Request</button>
      </div>
      <div className={classes.demo}>
        <pre className={"prism-code language-json"}>
          <code>{JSON.stringify(busData, null, 4)}</code>
        </pre>
      </div>
    </section>
  )
}

export default Demo
