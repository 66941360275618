import React, { useState } from "react"
import * as classes from "./home.module.scss"
import Demo from "../demo/Demo"

const Home = () => {
  const [spiel, setSpiel] = useState("shorter")
  const [focused, setFocused] = useState(null)

  const spiels = ["longwinded", "shorter", "short", "tldr"]

  function handleChange(e) {
    setSpiel(e.target.value)
  }
  function handleFocus(e) {
    setFocused(e.target.value)
  }
  function handleBlur(e) {
    setFocused(null)
  }
  return (
    <div className={classes.homeWrap}>
      <article>
      <h1 className={"_900"}>About Galway Bus API</h1>
        
        <form id="spiel">
        <fieldset>
          <legend><small>Manage description length:</small></legend>
        <div className={classes.radios}>
          {spiels.map(s => (
            <div key={s} className={classes.radiosGroup}>
              <input
                type="radio"
                id={s}
                name="spiel"
                value={s}
                className={classes.spielBtn}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                checked={spiel === s ? true : false}
              />
              <label
                htmlFor={s}
                className={`${spiel === s && classes.checked} ${
                  focused === s && classes.focused
                }`}
              >
                {s}
              </label>
            </div>
          ))}
          </div>
        </fieldset>
        </form>
        {spiel === "longwinded" ? (
          <div className={classes.text}>
            <p>
              Until 2020 Transport for Ireland provided bus information
              (including realtime) via the RTPI REST api. The data is still
              available, now in GTFS and GTFS-R (specifications). Consequently
              the barrier for entry is higher and tedious.
            </p>
            <p>Dealing with GTFS involves two things:</p>

            <ol>
              <li>
                {" "}
                <strong>Static information</strong> - routes, timetables, bus
                stops etc. It can be downloaded from{" "}
                <a href="https://www.transportforireland.ie/transitData/PT_Data.html">
                  nationaltransport.ie
                </a>
                . Some of the files are BIG and you need to put it into a
                database and write some fairly annoying queries to get anything
                meaningful.
              </li>
              <li>
                <strong>GTFS-R feed</strong> - this is the realtime information.
                You can sign up for a GTFS-R feed key at{" "}
                <a href="https://developer.nationaltransport.ie/apis">
                  nationaltransport.ie
                </a>{" "}
                and read about the specification at{" "}
                <a href="https://gtfs.org/reference/realtime/v2/">gtfs.org</a>.
                The feed data is only useful in conjunction with the static
                data.
              </li>
            </ol>
            <p>
              <strong>Galway Bus API</strong> is a REST API that serves all this
              information for Galway City Buses without any GTFS palava. Read
              the documentation & see{" "}
              <a href="https://busload.ie"> busload.ie</a>.
            </p>
          </div>
        ) : spiel === "shorter" ? (
          <div className={classes.text}>
            <p>
              Irish Bus information (including realtime) is available from{" "}
              <a href="https://www.transportforireland.ie/transitData/PT_Data.html">
                nationaltransport.ie
              </a>{" "}
              but because it is in GTFS and GTFS-R (specifications) it's tedious
              to get up and running.
            </p>

            <p>
              <strong>Galway Bus API</strong> is a REST API that serves
              information for Galway City Buses without any GTFS palava. Read
              the documentation & see{" "}
              <a href="https://busload.ie"> busload.ie</a>.
            </p>
          </div>
        ) : spiel === "short" ? (
          <div className={classes.text}>
            <p>
              <strong>Galway Bus API</strong> is a REST API serving realtime
              information about Galway City Buses.
            </p>
          </div>
        ) : spiel === "tldr" ? (
          <div className={classes.text}>
            <p>Takes the fs out of GTFS, try the demo.</p>
          </div>
        ) : null}
      </article>

      <section className={classes.baseUrl}>
      <h2>Base Url</h2>
      <a href="https://galway-bus.apis.ie/api">https://galway-bus.apis.ie/api</a>
      </section>
      <Demo />
    </div>
  )
}

export default Home
